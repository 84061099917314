import { Location } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
  AuthService,
  CanComponentDeactivate,
  DepotValidators,
  HelperService,
  ImageService,
  RoleGuard,
  ValidationError,
  WindowWrapper,
} from '@depot/@common';
import { CameraComponent } from '@depot/@components';
import {
  DealerRepositoryService,
  DealerReturnRepositoryService,
  PartRepositoryService,
  PutawayRepositoryService,
  WeightTicketRepositoryService
} from '@depot/@data';
import {
  IClientError,
  IDealer,
  IDealerReturnScrap,
  IDealerReturnWeightTicket,
  IPart,
  IPartImage,
  IProductLine,
  IPutAwayReport,
  IPutAwayRow,
  IScrapReport
} from '@depot/custom';
import { PutawayHelpComponent, PutawayScrapDialogComponent } from '@depot/putaway';
import { environment } from '@env';

import { BehaviorSubject, catchError, debounceTime, finalize, first, fromEvent, lastValueFrom, map, Observable, of, throttleTime, throwError, zip } from 'rxjs';

import { random, sortBy, union, unique } from 'underscore';

@Component({
  selector: 'depot-putaway-edit',
  templateUrl: './putaway-edit.component.html',
  styleUrls: ['./putaway-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PutawayEditComponent implements OnInit, AfterViewInit, CanComponentDeactivate {


  @ViewChild(CameraComponent) public depotCamera: CameraComponent;
  public form: UntypedFormGroup;
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public selectedDealer$ = new BehaviorSubject<IDealer>(null);
  public availableLocations$ = new BehaviorSubject<string[]>([]);
  public runningTotal$ = new BehaviorSubject<number>(0);
  public pendingChanges$ = new BehaviorSubject<number>(0);
  public lastRowLocation$ = new BehaviorSubject<string>('');
  public selectedFilterLocation: string | null = null;
  public lastFocusRow$ = new BehaviorSubject(0);
  public productLines$ = new Observable<IProductLine[]>();
  public openWeightTickets$ = new BehaviorSubject<IDealerReturnWeightTicket[]>([]);
  public isDealerReturnReadOnly$ = new BehaviorSubject(false);
  public isPutawayReadOnly$ = new BehaviorSubject(false);

  private formLockedDueToErrors = false;
  private putawayReport: IPutAwayReport = null;
  private isSaving = false;
  private maxRowsPerLocation = 35;
  private dealerReturnScrap: IDealerReturnScrap;

  constructor(
    private partRepo: PartRepositoryService,
    private dealerRepo: DealerRepositoryService,
    private putawayRepo: PutawayRepositoryService,
    public authService: AuthService,
    public imageSvc: ImageService,
    public helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private weightTicketService: WeightTicketRepositoryService,
    private dealerReturnService: DealerReturnRepositoryService,
    private window: WindowWrapper,
    private dialog: MatDialog,
    private auth: AuthService,
    private location: Location,
    private destroyRef: DestroyRef,
  ) {


  }
  ngOnInit(): void {

    this.isPutawayReadOnly$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => {
        if (!this.form) {
          return;
        }
        if (x) {
          this.form.get('partLine').disable();
        } else {
          this.form.get('partLine').enable();
        }
      });

    this.isDealerReturnReadOnly$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isDealerReturnReadonly) => {
        if (!this.form) {
          return;
        }
        if (isDealerReturnReadonly) {
          this.form.get('dealerReturnSize').disable();
          this.form.get('dealerReturnWeightTicketId').disable();

        } else {
          this.form.get('dealerReturnSize').enable();
          this.form.get('dealerReturnWeightTicketId').enable();
        }
      });

    const params = this.activatedRoute.snapshot.params;
    this.productLines$ = this.partRepo.getProductLines()
      .pipe(
        map(x => {
          const productLines = x.filter(y => y.internalName !== null);
          const arr = unique(productLines, item => item.lineCode);
          return sortBy(arr, item => item.internalSort);
        })
      );
    if (params['id'] !== 'new') {
      zip(
        this.putawayRepo.getPutAwayData(params['id']),
        this.dealerReturnService.getDealerReturnScrapByPutAwayReportId(params['id']).pipe(catchError(error => of(null)))
      )
        .subscribe(([putAwayData, dealerReturnScrap]) => {
          this.initializeForm(putAwayData, dealerReturnScrap);

          const canChange = this.auth.isInRole(RoleGuard.ScrapAdmin) || this.auth.user.userName === dealerReturnScrap?.createdBy;

          if (!canChange && dealerReturnScrap) {
            this.isDealerReturnReadOnly$.next(true);
          }
          if (putAwayData != null) {
            this.isPutawayReadOnly$.next(true);
          }
        });
    } else {
      this.initializeForm(null, null);
    }

  }

  ngAfterViewInit() {
    fromEvent(this.window, 'beforeunload')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: BeforeUnloadEvent) => {
        if (this.form && this.form.dirty) {
          x.returnValue = 'You have pending changes';
        }
        return x;
      });

  }
  private initializeForm(putAwayData: IPutAwayReport, dealerReturnScrap: IDealerReturnScrap) {
    // const line = putAwayData.length > 0 ? putAwayData[0].partLine : '';
    // const dealerNumber = putAwayData.length > 0 ? putAwayData[0].dealerNumber : '';
    // const scrapReportId = putAwayData.length > 0 ? putAwayData[0].scrapReportId : '';
    this.putawayReport = putAwayData;
    this.dealerReturnScrap = dealerReturnScrap;
    this.form = this.fb.group({
      id: putAwayData?.id ?? -1,
      groupId: putAwayData?.groupId ?? this.helper.getGuid(),
      partLine: this.fb.control(putAwayData?.partLine, { updateOn: 'change' }),
      dealerNumber: putAwayData?.dealerNumber,
      verifiedBy: putAwayData?.verifiedBy,
      verifiedDate: putAwayData?.verifiedDate,

      dealerReturnWeightTicketId: dealerReturnScrap?.dealerReturnWeightTicketId,
      dealerReturnSize: dealerReturnScrap?.size,
      dealerReturnScrapId: dealerReturnScrap?.id ?? 0,
      rows: this.fb.array([])
    }, { updateOn: 'blur' });
    if (putAwayData?.putAwayRows) {
      for (let i = 0; i < putAwayData.putAwayRows.length; i++) {
        if (putAwayData.putAwayRows[i].part?.description.includes('SameAs')
          || putAwayData.putAwayRows[i].part?.description.includes('CHECKCROSSREF')) {
          putAwayData.putAwayRows[i].part = null;
        }


        this.addFormRow(putAwayData.putAwayRows[i]);
        // this.onPartChange(this.formRows()[i], i, false);
      }
    }

    this.onDealerChange(putAwayData?.dealerNumber);
    this.addFormRow(null);
    if (putAwayData?.putAwayRows?.length > 0) {
      this.onRowBlur(this.formRows().length - 2);
    }
    // putAwayData.forEach(data => this.addFormRow(data));
    this.form.valueChanges
      .pipe(
        throttleTime(1000),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(x => this.onFormChange(x));

    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(x => this.saveForm(true));

    this.pendingChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => {
        if (x > 5) {
          this.saveForm(false);
        }
      });
    this.onFormChange(null);
    this.helper.IsGlobalBar$.set(false);

  }

  public sortArray() {
    const form = this.form.getRawValue();
    const lastRow = form.rows.at(-1);
    const rows = form.rows.sort((a, b) => a.quantity - b.quantity);
    rows.push(lastRow);
    this.form.get('rows').patchValue(rows);
  }

  // public imagesChanged(row: UntypedFormArray, changedData: { partLine: string; partNumber: string }) {
  //   if (changedData) {
  //     const rows = this.formRows();
  //     for (let i = 0; i < rows.length; i++) {
  //       const currentRow = rows.at(i).value as any;
  //       if (currentRow.partLine === changedData.partLine &&
  //         currentRow.partNumber === changedData.partNumber) {
  //         currentRow.partImages$.value.push(changedData);
  //         DepotValidators.persistPartImages(rows.at(i));
  //         break;
  //       }
  //     }
  //     this.formRows().at(this.lastFocusRow$.value);
  //   }
  //   DepotValidators.persistPartImages(row);
  // }
  /**
   * Takes a base64 encoded URL and adds it to the last row that had focus
   * @param url Base64 encoded URL
   */
  async capture(url: string, index: number) {

    if (url === null) {
      this.helper.showMessage('No camera available', 'error');
      return;
    }
    const row = this.formRows().at(index).value as IPutAwayRow;
    const byteArray = await this.imageSvc.base64ToBytesAsync(url);
    if (row.id === -1) {
      // this.helper.showMessage('You need to save a new row before adding an image', 'error');
      const tempImage = {
        id: -1 * random(1, 999999),
        fileStreamId: null,
        putAwayRowId: -1,
        partLine: this.form.getRawValue().partLine,
        partNumber: row.partNumber,
        isExternal: true,
        sortOrder: 0,
        fileStream: byteArray,
        imagePath: url,
      };
      const rows = this.rowImages(index);
      rows.unshift(this.mapFormImage(tempImage));
      (this.formRows().at(index) as UntypedFormGroup).get('partImages$').value.next(rows);
      return;
    }
    const newImage: IPartImage = {
      id: -1,
      fileStreamId: null,
      putAwayRowId: row.id,
      partLine: this.form.getRawValue().partLine,
      partNumber: row.partNumber,
      isExternal: true,
      sortOrder: 0,
      fileStream: byteArray,
    };

    this.partRepo.saveImage(newImage)
      .pipe(catchError(() => {
        this.helper.showMessage('Couldn\'t save the image', 'error');
        return of(null);
      }))
      .subscribe(x => {
        if (!x) { return; }
        const rows = this.rowImages(index);
        rows.unshift(this.mapFormImage(x));
        (this.formRows().at(index) as UntypedFormGroup).get('partImages$').value.next(rows);

        this.helper.showMessage('Image saved successfully', 'success');

        setTimeout(() => {
          DepotValidators.persistPartImages(this.formRows().at(index));
          const imageRow = document.getElementsByClassName('item-row-selected').item(0);
          if (!this.helper.isInViewport(imageRow) && imageRow) {
            imageRow.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, 0);

      });
  }

  private onFormChange(observ) {
    const formData = this.formRows().getRawValue() as IPutAwayRow[];
    let pending = 0;
    for (let i = 0; i < this.formRows().length; i++) {
      const row = this.formRows().at(i);
      if (row.dirty) {
        pending++;
      }
    }

    this.pendingChanges$.next(pending);
    const locations = formData.filter(x => (x.partLocation || '').length > 0 && (x.partNumber || '').length > 0);
    this.availableLocations$.next(sortBy(unique(locations.map(x => x.partLocation))));
    if (this.selectedFilterLocation !== null) {
      const currentLocationItems = locations.filter(item => item.partLocation === this.selectedFilterLocation);
      this.runningTotal$.next(unique(currentLocationItems.map(x => x.partNumber)).length);
      this.lastRowLocation$.next(this.selectedFilterLocation);
    } else {
      const lastPart = locations.at(-1);
      if (lastPart) {

        const currentLocationItems = locations.filter(item => item.partLocation === lastPart.partLocation);
        this.runningTotal$.next(unique(currentLocationItems.map(x => x.partNumber)).length);
        this.lastRowLocation$.next(lastPart.partLocation);
      }
    }
    return observ;
  }

  public onFilterChange(selectedLocation: string) {
    this.selectedFilterLocation = selectedLocation;
    const controls = this.formRows().controls;
    let firstVisibleRow = -1;
    for (let i = 0; i < controls.length; i++) {
      const rawValue = controls[i].value as IPutAwayRow;
      let isHidden = false;
      if (selectedLocation === '-1') {
        isHidden = controls[i].valid;
      } else {
        isHidden = rawValue.partLocation !== selectedLocation && selectedLocation !== null;
      }
      if (firstVisibleRow === -1 && isHidden === false) {
        this.lastFocusRow$.next(i);
        firstVisibleRow = i;
      }
      controls[i].get('hidden').setValue(isHidden, { onlySelf: true });

    }
    this.onFormChange(null);
  }

  public async saveForm(isAutomatic = true) {
    if (this.isSaving === true || (!this.form.dirty && isAutomatic === true)) {
      return;
    }
    if (!this.helper.validateForm(this.form, !isAutomatic)) {
      if (!isAutomatic) {
        this.helper.showMessage('Form is invalid', 'error');
      }
      return;
    } else if (this.formRows().length <= 1) {
      this.helper.showMessage('You must have at least one item added');
      return;
    }

    try {
      this.isSaving = true;
      this.globalErrors$.next([]);
      this.helper.IsGlobalSpinner$.set(true);
      // const groupIdRow = this.form.get('groupId');
      // const partLineRow = this.form.get('partLine');
      // const dealerNumberRow = this.form.get('dealerNumber');
      const dealerReturnWeightTicketId = this.form.get('dealerReturnWeightTicketId');
      const dealerReturnSize = this.form.get('dealerReturnSize');
      if (dealerReturnWeightTicketId.dirty || dealerReturnSize.dirty) {
        this.onDealerReturnScrapChange();
        if (!this.formRows().dirty) {
          this.helper.IsGlobalSpinner$.set(false);
          this.isSaving = false;
          return;
        }
      }
      const originalPutawayReport = {
        id: this.putawayReport?.id,
        dealerNumber: this.putawayReport?.dealerNumber,
        groupId: this.putawayReport?.groupId,
        partLine: this.putawayReport?.partLine,
        putAwayRows: this.putawayReport?.putAwayRows?.map(row => ({
          id: row.id,
          groupId: row.groupId,
          partNumber: row.partNumber,
          description: row.description,
          partLocation: row.partLocation,
          quantity: row.quantity,
          putAwayReportId: row.putAwayReportId,
        })),
      };

      const formRows = this.formRows();
      const formData = this.form.getRawValue();
      const input: IPutAwayReport = {
        id: formData.id,
        dealerNumber: formData.dealerNumber,
        groupId: formData.groupId,
        // verifiedBy: formData.verifiedBy,
        // verifiedDate: formData.verifiedDate,
        partLine: formData.partLine,
        putAwayRows: [],
      };

      let errorCount = 0;
      // don't do the last row because the validation isn't set until its partNumber value changes
      for (let idx = 0; idx < formRows.length - 1; idx++) {
        const row = formRows.at(idx);
        if (row.valid && row.value.partNumber) {
          const val = row.value as IPutAwayRow;
          const data: IPutAwayRow = {
            id: val.id,
            groupId: formData.groupId,
            partNumber: val.partNumber,
            description: val.description,
            partLocation: val.partLocation,
            quantity: val.quantity,
            putAwayReportId: val.putAwayReportId,
          };
          // tempNewRows.push(row);
          input.putAwayRows.push(data);
        } else {
          errorCount++;
        }
      }


      // if (input.putAwayRows.length === 0) {
      if (errorCount > 0) {
        // let message = 'No rows have pending changes';
        if (!isAutomatic) {

          this.helper.showMessage(`${errorCount} error(s) found`, 'info');
        }

        this.helper.IsGlobalSpinner$.set(false);
        this.isSaving = false;
        return;
      }
      // }

      let saveObservable: Observable<IPutAwayReport>;
      if (!this.putawayReport) {
        saveObservable = this.putawayRepo.savePutaway(formData.id, input);
      } else {
        saveObservable = this.putawayRepo.savePutawayPartial(formData.id, input, originalPutawayReport);
      }

      saveObservable.pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotModified && isAutomatic === true) {
            return of();
          }
          this.helper.mapErrors(error, this.formRows().controls, this.form, this.globalErrors$);
          this.helper.showMessage('Error saving changes', 'error');
          return of();
        }),
        finalize(() => {
          this.helper.IsGlobalSpinner$.set(false);
          this.isSaving = false;
          if (this.formLockedDueToErrors === true) {
            this.formLockedDueToErrors = false;
            this.addFormRow(null);
          }
        })
      )
        .subscribe(async (serverReport: IPutAwayReport) => {
          if (serverReport) {
            this.location.go('/putaway/' + serverReport.id);


            this.putawayReport = serverReport;
            this.form.patchValue({
              id: serverReport.id,
              groupId: serverReport.groupId,
              dealerNumber: serverReport.dealerNumber,
              partLine: serverReport.partLine,

            });
            let allValid = true;
            for (let idx = 0; idx < serverReport.putAwayRows.length; idx++) {
              const serverRow = serverReport.putAwayRows[idx];
              const thisRow = this.formRows().at(idx);
              // set the Id either way
              thisRow.patchValue({
                id: serverRow.id
              });
              // only update the putaway values if they are the same, otherwise they should be picked up on
              // the next change check
              if (
                serverRow.partNumber === thisRow.value.partNumber &&
                serverRow.description === thisRow.value.description &&
                serverRow.quantity === thisRow.value.quantity &&
                serverRow.partLocation === thisRow.value.partLocation) {
                thisRow.patchValue({
                  groupId: serverRow.groupId,
                  partNumber: serverRow.partNumber,
                  description: serverRow.description,
                  quantity: serverRow.quantity,
                  partLocation: serverRow.partLocation,
                  putAwayReportId: serverRow.putAwayReportId,
                }, { emitEvent: false, onlySelf: true });
                thisRow.markAsPristine();
              } else {
                allValid = false;
              }
            }
            // const formData = this.form.getRawValue();
            const currentFormData = this.form.getRawValue();
            for (let rowIndex = 0; rowIndex < currentFormData.rows.length; rowIndex++) {
              const row = currentFormData.rows[rowIndex];
              const imgPathsToSave: { image: string; index: number }[] = [];
              const partImages: IPartImage[] = row.partImages$.value;
              for (const img of partImages.filter(x => x.id < 0)) {
                imgPathsToSave.push({ image: img.imagePath, index: rowIndex });
              }

              row.partImages$.next(partImages.filter(x => x.id > 0));

              imgPathsToSave.forEach(x => this.capture(x.image, x.index));
            }
            this.pendingChanges$.next(0);
            this.isPutawayReadOnly$.next(true);
            this.onDealerReturnScrapChange();
            if (allValid) {
              this.form.markAsPristine();

            }
            if (!isAutomatic) {
              this.helper.showMessage('Changes saved successful', 'success');
            }

          }
        });

    } catch (err) {
      this.helper.IsGlobalSpinner$.set(false);
      this.isSaving = false;
      if (err instanceof ValidationError) {
        if (!isAutomatic) {
          this.helper.showMessage(err.message, 'error');

        }
      } else {
        throw err;
      }
    }
  }

  public async removeFormItem(index: number, rowKey: string) {
    if (await this.helper.confirmDialog('Are you sure you want to delete this item?')) {
      const row = this.formRows().at(index).value;
      const isDirty = this.form.dirty;
      if (row.id === -1) {
        this.formRows().removeAt(index);
        return;
      }
      this.putawayRepo.deletePutAway(row.id)
        .pipe(catchError((errors: any) => {
          this.helper.logger.error('Error removing putaway row', errors);

          this.helper.showMessage('Error removing row', 'error');
          return of();
        })).subscribe(() => {
          this.formRows().removeAt(index);
          const rows = this.putawayReport.putAwayRows;
          this.putawayReport.putAwayRows.splice(rows.findIndex(x => x.id === row.id), 1);
          if (isDirty === false) {
            this.form.markAsPristine();
          }
        });
    }

  }

  public exportData() {
    this.globalErrors$.next([]);
    this.helper.IsGlobalSpinner$.set(true);

    this.putawayRepo.exportPutAwayData(this.form.getRawValue().id)
      .pipe(
        catchError((x: IClientError) => {
          this.helper.mapErrors(x, null, this.form, this.globalErrors$);
          this.helper.showMessage('Error during export', 'error');
          return of();
        }),
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      )
      .subscribe((x) => {
        if (x) {
          this.helper.showMessage('File exported successfully', 'success');
        }
      });
  }

  public async showHelp() {
    await this.helper.showAlert('Loading...', null, PutawayHelpComponent);
  }

  public async showScrapDialog() {
    this.dialog.open(PutawayScrapDialogComponent, {
      data: {
        dealerNumber: this.form.get('dealerNumber').value,
        putAwayReportId: this.form.get('id').value,
        dealerReturnSize: this.form.get('dealerReturnSize').value,
        dealerReturnWeightTicketId: this.form.get('dealerReturnWeightTicketId').value,
      },
      disableClose: true,
      maxHeight: '100vh',
      minWidth: '50vw',
    })
      .afterClosed()
      .pipe(first())
      .subscribe(savedScrap => {
        if (savedScrap) {
          this.dealerReturnScrap = savedScrap;
        }
      });

  }

  public onDealerChange(dealerNumber: string) {
    const dealerReturnWeightTicketIdRow = this.form.get('dealerReturnWeightTicketId');
    const dealerReturnSizeRow = this.form.get('dealerReturnSize');
    const dealerReturnScrapIdRow = this.form.get('dealerReturnScrapId');

    if (dealerNumber?.length === 5) {
      this.dealerRepo.getDealer(dealerNumber)
        .pipe(catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            this.form.get('dealerNumber').setErrors({ 'missing': 'Invalid dealer' });
            return of(null);
          }
          return throwError(() => err);
        })).subscribe(x => {
          this.selectedDealer$.next(x);
        });

      this.weightTicketService.GetWeightTicketsByDealerNumber(dealerNumber)
        .pipe(catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            dealerReturnWeightTicketIdRow.setValue(null);
            dealerReturnScrapIdRow.setValue(0);
            dealerReturnSizeRow.setValue(null);

            return of([]);
          }
          return throwError(() => err);
        }))
        .subscribe(weightTicket => {
          if (weightTicket.length === 0 && this.dealerReturnScrap) {
            this.openWeightTickets$.next([this.dealerReturnScrap.weightTicket]);

          } else {
            const relevantWeightTickets = weightTicket.filter(x => x.dealerReturn.warehouse === this.auth.user.location);
            this.openWeightTickets$.next(relevantWeightTickets);
            if (relevantWeightTickets.length === 1) {
              dealerReturnWeightTicketIdRow.setValue(relevantWeightTickets[0].id);
            }
          }
        });

    } else {
      this.selectedDealer$.next(null);
      this.openWeightTickets$.next([]);
      dealerReturnWeightTicketIdRow.setValue(null);
      dealerReturnScrapIdRow.setValue(0);
      dealerReturnSizeRow.setValue(null);

    }
  }

  public onPartLineChanged() {
    const partLine = this.form.get('partLine').value;
    if (partLine && partLine.length === 1) {
      const rows = this.formRows();
      for (let i = 0; i < rows.length; i++) {
        this.onPartChange(rows.at(i), i, false);
      }
      this.onFormChange(null);
    } else {
      this.helper.showMessage('You must supply a Product Line', 'error');
    }
  }

  public onPartChange(row: AbstractControl, index: number, autoAddRow = true) {
    const partNumber = row.get('partNumber').value;
    const line = this.form.get('partLine').value;
    if (line && line.length === 1 && partNumber && partNumber.length > 0) {
      this.partRepo.getPart(line, partNumber)
        .pipe(catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            // this.priceBookRepo.getPriceBookLine(line, partNumber, false, true, false)
            //   .pipe(catchError(x => of(null)))
            //   .subscribe((serverPriceBook: IPriceBookLine[]) => {
            //     if (serverPriceBook) {
            //       const priceBook = first(sortBy(serverPriceBook, x => x.date).reverse());
            //       row.get('priceBookLine$').value.next(priceBook);
            //       row.get('description').setValue(priceBook.description);

            //     } else {
            //       row.get('priceBookLine$').value.next(null);

            //     }
            //   });
            row.get('part$').value.next(null);
            row.get('part$').updateValueAndValidity();
            const itemsToKeep: IPartImage[] = [];
            const imageRow = structuredClone(row.get('partImages$').value.value) as IPartImage[];

            while (imageRow.length > 0) {
              if (imageRow[0].putAwayRowId === row.value.id) {
                itemsToKeep.push(imageRow[0]);
              }
              imageRow.splice(0, 1);
            }
            // add images that aren't already in the list
            row.get('partImages$').value.next(itemsToKeep);
            row.get('partImages$').markAsDirty();

            return of(null);
          }
          this.helper.logger.error('Error getting part info', err);
          return throwError(() => err);
        })).subscribe((serverPart: IPart) => {
          if (serverPart && serverPart.description
            && !serverPart.description.includes('SameAs')
            && !serverPart.description.includes('CHECKCROSSREF')) {
            row.get('part$').value.next(serverPart);
            row.get('part$').updateValueAndValidity();
            row.get('description').setValue(serverPart.description);
          }

          const itemsToKeep: IPartImage[] = [];
          const imageRow = structuredClone(row.get('partImages$').value.value) as IPartImage[];

          while (imageRow.length > 0) {
            if (imageRow[0].putAwayRowId === row.value.id) {
              imageRow[0].partLine = line;
              imageRow[0].partNumber = partNumber;
              this.partRepo.saveImage(imageRow[0]).subscribe();

              itemsToKeep.push(imageRow[0]);
            }
            imageRow.splice(0, 1);
          }
          const images = union(itemsToKeep, serverPart?.partImages, serverPart?.partImagePartials);
          const imageGroup = unique(images, x => x.id).map(img => this.mapFormImage(img));
          // add images that aren't already in the list
          for (let i = 0; i < imageGroup.length; i++) {
            imageRow.push(imageGroup[i]);
          }
          row.get('partImages$').value.next(imageRow);
          row.get('partImages$').markAsDirty();
        });

    }

    if (autoAddRow === true && this.formRows().length - 1 === index) {
      this.addFormRow(null);


    }
  }

  public onDealerReturnScrapChange() {
    const dealerReturnWeightTicketId = this.form.get('dealerReturnWeightTicketId');
    const dealerReturnSize = this.form.get('dealerReturnSize');
    const dealerReturnScrapId = this.form.get('dealerReturnScrapId');
    const formData = this.form.getRawValue() as IScrapReport;
    const rawData = this.formRows().getRawValue() as IPutAwayRow[];

    if (!dealerReturnWeightTicketId.value || rawData.every(x => x.id <= 0)) {
      return;
    }
    const data = <IDealerReturnScrap>{
      id: dealerReturnScrapId.value,
      putAwayReportId: formData.id,
      dealerReturnWeightTicketId: dealerReturnWeightTicketId.value,
      size: dealerReturnSize.value,
      skipValidation: true,

    };

    const original = <IDealerReturnScrap>{
      id: this.dealerReturnScrap?.id,
      putAwayReportId: this.dealerReturnScrap?.putAwayReportId,
      dealerReturnWeightTicketId: this.dealerReturnScrap?.dealerReturnWeightTicketId,
      size: this.dealerReturnScrap?.size,
      skipValidation: null,
    };

    this.dealerReturnService.saveDealerReturnScrap(data, original)
      .pipe(catchError((error: IClientError) => {
        this.helper.logger.error('Error saving scrap report row', error);
        this.helper.mapErrors(error, null, this.form, this.globalErrors$);

        return of();
      }))
      .subscribe((x: IDealerReturnScrap) => {
        if (x) {
          this.dealerReturnScrap = x;
          dealerReturnScrapId.setValue(x.id);
          dealerReturnScrapId.markAsPristine();
          dealerReturnWeightTicketId.markAsPristine();
          dealerReturnSize.markAsPristine();
        }
      });
  }

  public onRowBlur(index: number) {
    const lastRow = this.formRows().controls[index + 1];
    if (this.formRows().length === index + 2 && lastRow) {
      const rawData = this.formRows().getRawValue() as IPutAwayRow[];

      const partNumber = lastRow.get('partNumber').value;
      const partLocation = lastRow.get('partLocation').value;
      const lastLocation = this.formRows().controls[index].get('partLocation').value;
      const locationCount = unique(rawData.filter(x => x.partLocation === lastLocation)
        .map(x => x.partNumber))
        .length;

      if (locationCount < this.maxRowsPerLocation && partNumber.length === 0 && partLocation.length === 0) {

        lastRow.get('partLocation').setValue(lastLocation);
        lastRow.markAsPristine();
      }
    }
  }

  // private saveFormRow(formRow: AbstractControl<IPutAwayRow>) {
  //   if (formRow.dirty && formRow.valid) {

  //   }
  // }

  // public onRowFocus(event: FocusEvent, index: number) {
  //   const element = (event.target as HTMLInputElement);
  //   element.setSelectionRange(element.value.length, element.value.length);
  //   this.lastFocusRow$.next(index);

  // }

  // public trackBy(index: number, row: AbstractControl) {
  //   return row.get('rowKey').value;
  // }

  public addFormRow(row: IPutAwayRow | null) {
    if (this.pendingChanges$.value > 1) {
      if (!this.helper.validateForm(this.form, false)) {
        this.helper.showMessage('You have rows that are invalid, please fix invalid rows before continueing', 'warn', 60000, 'Ok', (snackbar) => snackbar.dismiss());
        this.formLockedDueToErrors = true;
        return;
      }
    }
    if (!row) {
      row = <any>{};
    }
    let images: IPartImage[] = row.partImages || row.partImagePartial || [];
    if (row.part) {
      images = union(images, row.part.partImages, row.part.partImagePartials);
    }
    const rowKey = this.helper.getGuid();
    const formGroup = this.fb.group({
      rowKey: [rowKey],
      id: row.id || -1,
      putAwayReportId: row.putAwayReportId || -1,
      partNumber: this.fb.control(row.partNumber || '', { updateOn: 'change', validators: [DepotValidators.nonUniqueDescriptions()] }),
      description: this.fb.control(row.description || '', [DepotValidators.nonUniqueDescriptions()]),
      quantity: this.fb.control(row.quantity || null, [Validators.min(1), Validators.max(2000)]),
      partLocation: this.fb.control(row.partLocation || '', [DepotValidators.maxPerLocation(this.maxRowsPerLocation)]),
      part$: new BehaviorSubject<IPart>(row.part || null),
      priceBookLine$: new BehaviorSubject(row.currentPriceBookLine),
      hidden: false,
      partImages$: new BehaviorSubject(images.map(img => this.mapFormImage(img)))
    }, { updateOn: 'blur' });
    this.formRows().push(formGroup);
  }

  private formRows() {
    return this.form.get('rows') as UntypedFormArray;
  }

  private rowImages(index: number) {
    return (this.formRows().controls[index] as UntypedFormGroup).get('partImages$').value.value as IPartImage[];
  }

  private mapFormImage(img: IPartImage) {
    return {
      id: img.id,
      fileStreamId: img.fileStreamId,
      putAwayRowId: img.putAwayRowId,
      auditRowId: img.auditRowId,
      partLine: img.partLine,
      partNumber: img.partNumber,
      isExternal: img.isExternal,
      sortOrder: img.sortOrder || 0,
      fileStream: img.fileStream,
      imagePath: img.imagePath ?? environment.get_endpoint('part/partimage/' + img.fileStreamId),
    };
  }

  async canDeactivate() {
    if (this.form && (this.form.dirty || this.form.invalid)) {
      this.helper.logger.info('User attempting to leave with for changes', null, {
        dirtyRows: this.formRows().controls.filter(x => x.dirty).length,
        invalidRows: this.formRows().controls.filter(x => x.invalid).length,
      });
      this.onFilterChange('-1');
      return this.helper.confirmDialog('You have pending changes, do you want to leave and lose those changes?');
    }
    if (this.openWeightTickets$.value.length > 0 && this.formRows().length > 0) {
      const row = await lastValueFrom(this.dealerReturnService.getDealerReturnScrapByPutAwayReportId(this.form.get('id').value));
      if (!row) {
        this.helper.showMessage('A scrap row is required for this record');
        return this.helper.confirmDialog('A scrap row is required for this putaway and one is not saved.  Do you still want to leave the page?');
      }
    }
    return true;
  }

}


