<h1 mat-dialog-title>Scrap Info</h1>
<div mat-dialog-content>

  <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>

  <div *ngIf="scrapRow$ | async as scrapRow"
       [formGroup]="form">

    <div class="tw-flex tw-flex-row tw-justify-center">
      <div class="tw-p-2">
        <span class="tw-font-extrabold">Date Arrived:</span>
        {{scrapRow.weightTicket?.dealerReturn?.dateArrived | date:'yyyy-MM-dd'}} -
        {{scrapRow.weightTicket?.dealerReturn?.warehouse}}
      </div>

      <div class="tw-p-2">
        <span class="tw-font-extrabold">Size:</span>
        {{scrapRow.size}}
      </div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-center">

      <mat-form-field [class.readonly]="isReadOnly$ | async">
        <!--  -->
        <mat-label>Regular Ibs</mat-label>
        <input matInput
               formControlName="regularIbs"
               type="number"
               min="0"
               [readonly]="isReadOnly$ | async" />
        <mat-error [depot-form-error]="form"
                   errorControlName="regularIbs">
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%;"
                      [class.readonly]="isReadOnly$ | async">
        <mat-label>Regular Comments</mat-label>
        <input matInput
               formControlName="regularComments"
               type="text"
               [readonly]="isReadOnly$ | async" />
        <mat-error [depot-form-error]="form"
                   errorControlName="regularComments">
        </mat-error>
      </mat-form-field>

    </div>

    <div class="tw-flex tw-flex-row tw-justify-center">

      <mat-form-field [class.readonly]="isReadOnly$ | async">
        <!--  -->
        <mat-label>Audit Ibs</mat-label>
        <input matInput
               formControlName="auditIbs"
               type="number"
               min="0"
               [readonly]="isReadOnly$ | async" />
        <mat-error [depot-form-error]="form"
                   errorControlName="auditIbs">
        </mat-error>
      </mat-form-field>


      <mat-form-field style="width: 100%;"
                      [class.readonly]="isReadOnly$ | async">
        <mat-label>Audit Comments</mat-label>
        <input matInput
               formControlName="auditComments"
               type="text"
               [readonly]="isReadOnly$ | async" />
        <mat-error [depot-form-error]="form"
                   errorControlName="auditComments">
        </mat-error>
      </mat-form-field>

    </div>

  </div>

</div>
<div mat-dialog-actions align="end">

  <button mat-raised-button
          color="primary"
          (click)="dialogRef.close(null)">Cancel</button>

  <button mat-raised-button
          *ngIf="form && !(isReadOnly$ | async)"
          color="primary"
          (click)="save()">Save</button>

</div>
